import {useEffect} from 'react'
import {RestrictedHoursModalHeader} from './RestrictedHoursModalHeader'
import {RestrictedHoursModalFormWrapper} from './RestrictedHoursModalFormWrapper'

const RestrictedHoursModal = ({
  selectedListingId,
  setSelectedListingId,
  listing,
  getListing,
  setOpenRestrictedHoursModal,
}) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className={`modal-dialog modal-dialog-centered mw-900px`}>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <RestrictedHoursModalHeader setSelectedListingId={setSelectedListingId} listing={listing}  setOpenRestrictedHoursModal={setOpenRestrictedHoursModal} />
            {/* begin::Modal body */}
            <div className={`modal-body scroll-y mx-2 mx-xl-2 my-2 `}>
              <RestrictedHoursModalFormWrapper selectedListingId={selectedListingId} setSelectedListingId={setSelectedListingId} listing={listing} getListing={getListing} setOpenRestrictedHoursModal={setOpenRestrictedHoursModal} />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {RestrictedHoursModal}
