import { useEffect, useState, useMemo } from "react";
import { isNotEmpty, toAbsoluteUrl } from "../../../../_metronic/helpers";
import clsx from "clsx";
import axios from "axios";
import { toast } from "react-toastify";
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/layouts/mobile.css";
import opacity from "react-element-popper/animations/opacity";
import "./style.css";
import moment from "moment";
import { convertUTCTimeToListingTime, getLocalListingTime, convertListingTimeToUTC } from '../../../../_metronic/helpers/convertTime.js'

import dayjs from "dayjs";
import { object } from "prop-types";
const BookingEditModalForm = ({
  setSelectedBookingId,
  fetchBooking,
  selectedBookingId,
  setOpenEditBookingModal,
  fetchBookingId
}) => {
  const [startDate, setStartDate] = useState();
  const [clockIn, setClockIn] = useState("");
const [currentTime, setCurrentTime] = useState('')
const [today, setToday] = useState(false)

  const cancel = () => {
    setSelectedBookingId(null);
    setOpenEditBookingModal(false)
  };

  useEffect(() => {
    if (selectedBookingId) {
      setStartDate(dayjs(convertUTCTimeToListingTime(selectedBookingId?.start_date, selectedBookingId.timezone_id, 0, "YYYY-MM-DD")).toDate());
      setClockIn(selectedBookingId?.clock_in);
    }
  }, [selectedBookingId]);

//   useEffect(() => {
// console.log(typeof startDate)
//   },[startDate])


//   const isToday = (someDate) => {
//     const today = new Date()
//     return (
//         new Date(someDate).getDate() === today.getDate() &&
//         new Date(someDate).getMonth() === today.getMonth() &&
//         new Date(someDate).getFullYear() === today.getFullYear()
//     )
// }

const isToday = (someDate) => {
  const today = dayjs(getLocalListingTime(selectedBookingId?.time_offset)).toDate()
  if(typeof someDate === 'object'){
    return (
              new Date(someDate).getDate() === today.getDate() &&
              new Date(someDate).getMonth() === today.getMonth() &&
              new Date(someDate).getFullYear() === today.getFullYear()
          )
  }else{
    return (
      someDate?.getDate() === today.getDate() &&
      someDate?.getMonth() === today.getMonth() &&
      someDate?.getFullYear() === today.getFullYear()
    );
  }

};


// useEffect(() => {
//     if (startDate) {
//         const time = moment().format('HH:mm')
//         const isSelectedToday = isToday(selectedBookingId.booking_type === 1 ? startDate : startDate)
//         setCurrentTime(time)
//         setToday(isSelectedToday)
//     }
//     if(isToday(startDate)){
//         setClockIn('')
//     }
// }, [startDate])


useEffect(() => {
  if (startDate && selectedBookingId) {
    // const time = dayjs(getLocalListingTime(selectedBookingId?.time_offset)).format('HH:mm')
    const time = moment().tz(selectedBookingId.timezone_id).format('HH:mm')
    const isSelectedToday = isToday(
      selectedBookingId?.booking_type === 1 ? startDate : startDate
    );
    setCurrentTime(time);
    setToday(isSelectedToday);
  }
  if(isToday(startDate)){
    setClockIn('')
  }
}, [startDate, selectedBookingId]);




const EditBooking = () => {
  
  let date = dayjs(startDate).format('YYYY-MM-DD')
  let utcDate =   moment.tz(date + ' ' + clockIn, selectedBookingId.timezone_id).utc().format('YYYY-MM-DD HH:mm:00+00')
  axios.patch(`${process.env.REACT_APP_API_URL}/booking/${selectedBookingId.booking_id}`, {
    start_date: date,
    clock_in: clockIn,
  }).then((res) => {
    toast(`Booking Information Updated For Booking Number #${selectedBookingId.booking_id}`, {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    fetchBooking()
    fetchBookingId(selectedBookingId?.booking_id)
    setSelectedBookingId(null)
    setOpenEditBookingModal(false)
  }).catch((err)=>{
    if(err && err.response && err.response?.data && err.response?.data?.message){
      toast(err.response.data.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }else{
    toast("Technical Error, Please Try Again", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  })
}


  return (
    <>
      {/* begin::Scroll */}

      <div className="mt-4">
        <div className="fv-row mb-7">
          <label className="fw-bold fs-6 mb-2">Start Date</label>
          <DatePicker
            style={{
              width: "100%",
              boxSizing: "border-box",
              borderRadius: "8px",
              padding: "0.775rem 1rem",
              fontSize: "1.1rem",
              fontWeight: "500",
              lineHeight: "1.5",
              backgroundColor: "#f9f9f9",
              border: "1px solid #f9f9f9",
              color: "#5e6278",
              transition: "color 0.2s ease",
              height: "auto",
            }}
            containerStyle={{
              width: "100%",
            }}
            className="rmdp-mobile custom-calendar"
            calendarPosition="center"
            arrow={false}
            value={startDate}
            onChange={setStartDate}
            format="dddd MMM DD,YYYY"
            // render={<div style={{position:'relative'}}><input className="form-control form-control-solid mb-3 mb-lg-0" value={startDate} type="text"/> <i className="fas fa-calendar-alt" style={{color:'#ffa459', position:'absolute', top:'50%', right:'10px', transform:'translateY(-50%)'}}></i> </div>}
            fixMainPosition={true}
            minDate={dayjs(getLocalListingTime(selectedBookingId?.time_offset)).format("YYYY-MM-DD")}
            maxDate={dayjs(convertUTCTimeToListingTime(selectedBookingId?.end_date, selectedBookingId.time_offset, 0, "YYYY-MM-DD")).toDate()}
            animations={[opacity()]}
            placeholder="Select Date"
            weekDays={["S", "M", "T", "W", "T", "F", "S"]}
            scrollSensitive={false}
            hideOnScroll={false}
          />
        </div>
        <div className="fv-row mb-7">
          <label className="fw-bold fs-6 mb-2">
            Clock In (location time zone)
          </label>
          <select
            className="form-select form-select-solid fw-bolder"
            data-kt-select2="true"
            data-placeholder="Select option"
            data-allow-clear="true"
            data-kt-user-table-filter="two-step"
            data-hide-search="true"
            value={clockIn}
            onChange={(e) => setClockIn(e.target.value)}
          >
            <option value="">Select clock in (location time zone)</option>
            {hours.map((el) => (
              <option
                value={el.value}
                key={el.value}
                disabled={
                  today && el.value.split(":")[0] <= currentTime.split(":")[0]
                }
              >
                {el.text}
              </option>
            ))}
          </select>
        </div>
      </div>
      {/* end::Scroll */}

      {/* begin::Actions */}
      <div className="text-center pt-15">
        <button
          type="reset"
          onClick={() => cancel()}
          className="btn btn-light me-3"
          data-kt-users-modal-action="cancel"
        >
          Discard
        </button>
        <button
          type="submit"
          className="btn"
          data-kt-users-modal-action="submit"
          disabled={startDate === "" || clockIn === ""}
          style={{ background: "#ffa459", color: "#fff" }}
          onClick={EditBooking}
        >
          <span className="indicator-label">Edit Booking</span>
        </button>
      </div>
      {/* end::Actions */}
    </>
  );
};

export { BookingEditModalForm };


export const hours = [
  { text: '00:00 AM', value: '00:00:00' },
  { text: '01:00 AM', value: '01:00:00' },
  { text: '02:00 AM', value: '02:00:00' },
  { text: '03:00 AM', value: '03:00:00' },
  { text: '04:00 AM', value: '04:00:00' },
  { text: '05:00 AM', value: '05:00:00' },
  { text: '06:00 AM', value: '06:00:00' },
  { text: '07:00 AM', value: '07:00:00' },
  { text: '08:00 AM', value: '08:00:00' },
  { text: '09:00 AM', value: '09:00:00' },
  { text: '10:00 AM', value: '10:00:00' },
  { text: '11:00 AM', value: '11:00:00' },
  { text: '12:00 PM', value: '12:00:00' },
  { text: '01:00 PM', value: '13:00:00' },
  { text: '02:00 PM', value: '14:00:00' },
  { text: '03:00 PM', value: '15:00:00' },
  { text: '04:00 PM', value: '16:00:00' },
  { text: '05:00 PM', value: '17:00:00' },
  { text: '06:00 PM', value: '18:00:00' },
  { text: '07:00 PM', value: '19:00:00' },
  { text: '08:00 PM', value: '20:00:00' },
  { text: '09:00 PM', value: '21:00:00' },
  { text: '10:00 PM', value: '22:00:00' },
  { text: '11:00 PM', value: '23:00:00' },
  { text: '11:59 PM', value: '23:59:00' },
]
