import clsx from "clsx";

const Pagination = ({ page, setPage, setPageSize, pageSize, total, pageSizeOptions=[] }) => {
  return (
    <div className="row">
      <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <select
            className="form-select form-select-solid fw-bolder"
            data-kt-select2="true"
            data-placeholder="Select Status"
            data-allow-clear="true"
            data-kt-user-table-filter="two-step"
            data-hide-search="true"
            onChange={(e) => {
              setPageSize(e.target.value);
            }}
            value={pageSize}
          >
            {
            pageSizeOptions && pageSizeOptions.length > 0 ? pageSizeOptions.map((item, index) => <option key={index} value={item}>{item}</option>) :
            <>
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="500">500</option>
            </>
            }
          </select>
        </div>
      </div>
      <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
        <div id="kt_table_users_paginate">
          <ul className="pagination">
            {page > 1 && (
              <li className={clsx("page-item", "previous")}>
                <button
                  className={clsx("page-link", "next", "page-text")}
                  onClick={() => setPage(prev => prev - 1)}
                  style={{ cursor: "pointer" }}
                  disabled={page === 1}
                >
                  Previous
                </button>
              </li>
            )}

            <div style={{ display: "flex", gap: "5px", fontWeight: 700, fontSize: "14px" }}>
              <span style={{ color: "#ffa459" }}>{page}</span>/ {Math.ceil(total / pageSize)}
            </div>

            {(page < Math.ceil(total / pageSize)) && (
              <li className={clsx("page-item", "previous", page === Math.ceil(total / pageSize) && "disabled")}>
                <button
                  className={clsx("page-link")}
                  onClick={() => setPage(prev => prev + 1)}
                  style={{
                    cursor: page !== Math.ceil(total / pageSize) ? "pointer" : "default",
                    background: page !== Math.ceil(total / pageSize) ? "transparent" : "#e6e6e6",
                    color: page !== Math.ceil(total / pageSize) ? "#5e6278" : "#b2b2b2",
                    marginLeft: page === Math.ceil(total / pageSize) && "10px"
                  }}
                >
                  Next
                </button>
              </li>
            )}
            {Math.ceil(total / pageSize) > 5 && <div style={{ display: "flex", gap: "10px", alignItems: "center", marginLeft: '10px' }}>
              <h6 style={{ marginBottom: '0' }}>Go To Page: </h6>
              <select
                style={{ width: "max-content" }}
                className="form-select form-select-solid fw-bolder"
                data-kt-select2="true"
                data-placeholder="Select Status"
                data-allow-clear="true"
                data-kt-user-table-filter="two-step"
                data-hide-search="true"
                onChange={(e) => setPage(+e.target.value)}
                value={page}
              >
                {
                  [...Array(Math.ceil(total / pageSize))].map((_, i) => {
                    return (
                      <option value={i + 1}>{i + 1}</option>
                    )
                  }
                  )}
              </select>
            </div>
            }
          </ul>
        </div>
      </div>
    </div>
  );
};

export { Pagination };
