import { useState, useEffect } from "react";
import clsx from "clsx";
import {
  KTCard,
  KTIcon,
  KTCardBody
} from "../../../../_metronic/helpers";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import ImageViewer from "awesome-image-viewer";
import "./style.css";
import { Map } from "../../Map";
import { UserEditModal } from "./user-edit-modal/UserEditModal";
import { UserEditModal as AddAdditionalUserModal } from "./addAdditionalUserModal/UserEditModal";
import { PendingListingModal } from "./PendingListingModal/PendingListingModal";
import { UserEditModal as DeleteUserModal } from "./DeleteUserModal/UserEditModal";
import { UserEditModal as TimeLineModal } from "./TimelineModal/UserEditModal";
import { UserNewModal as AdminUserModal } from "./AdminUserModal/UserNewModal";
import { StripeConfirmModal } from "./StripeConfirmModal/StripeConfirmModal";
import { PromoModal } from "./PromoModal/PromoModal";
import { toast } from "react-toastify";
import { convertUTCTimeToListingTime } from "../../../../_metronic/helpers/convertTime.js";
import moment from "moment";
import { useAuth } from "../../auth";
import { UsersListLoading } from "./loading/UsersListLoading";
import Reviews from "../../../../components/Reviews";
import { getListingPrices, getColorByBookingStatus, daysOfWeek } from "../../../../_metronic/helpers/utils";
import { RestrictedHoursModal } from "./RestrictedHoursModal/RestrictedHoursModal";

const SingleListing = () => {
  const { currentUser, haveAccess } = useAuth();
  const navigate = useNavigate();
  const [listing, setListing] = useState();
  const [selectedListingId, setSelectedListingId] = useState();
  const [openCommissionModal, setOpenCommissionModal] = useState(false);
  const [openEditListing, setOpenEditListing] = useState(false);
  const [openAdditionalUserModal, setOpenAdditionalUserModal] = useState(false);
  const [openPendingUserModal, setOpenPendingUserModal] = useState(false);
  const [openDisableModal, setOpenDisableModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openStripeConfirmModal, setOpenStripeConfirmModal] = useState(false);
  const [openTimelineModal, setOpenTimelineModal] = useState(false);
  const [openPromoModal, setOpenPromoModal] = useState(false);
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openRestrictedHoursModal, setOpenRestrictedHoursModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState();
  const { id } = useParams();
  const [bookings, setBookings] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState("progress");
  const [amenities, setAmenities] = useState([]);
  const [pageSize, setPageSize] = useState(30);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [loading, setLoading] = useState(true);
  const [bookingsCount, setBookingsCount] = useState({});
  const [promoCodes, setPromoCodes] = useState([]);
  const [notes, setNotes] = useState([]);
  const [noteId, setNoteId] = useState(null);
  const [admins, setAdmins] = useState([]);
  const [vehicleType, setVehicleType] = useState('3');

  const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY
  const isProd = stripeKey.indexOf('pk_live') === 0
  const date = moment().format('YYYY-MM-DD')
  const getListing = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/listing/${id}`)
      .then((res) => {
        const listing = res.data;
        setListing(listing);
        setVehicleType(listing?.vehicle_type_allowed !== 0 ? listing?.vehicle_type_allowed : '3');
        getBookingsCount(listing?.listing_id);
      }).catch((err) => {
        console.log(err)
      });
  };


  useEffect(() => {
    getListing();

    axios.get(`${process.env.REACT_APP_API_URL}/listing/amenities`)
      .then((res) => {
        setAmenities(res.data);
      })
      .catch((err) => {
        console.log(err)
      });

    // axios.get(`${process.env.REACT_APP_API_URL}/promo?page=1&page_size=100&listing_id=${id}`)
    //   .then(res => {
    //     setPromoCodes(res.data.promo_codes);
    //   }).catch((err) => {
    //     console.log(err)
    //   });
  }, []);

  function formatPhone(phone) {
    phone = phone?.replace(/[^\d]/g, "");

    if (phone?.length === 11) {
      return phone?.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 ($2) $3-$4");
    } else if (phone?.length === 10) {
      return phone?.replace(/(\d{3})(\d{3})(\d{4})/, "+1 ($1) $2-$3");
    }

    return null;
  }

  const duration = (duration) => {
    switch (duration) {
      case 1:
        return "hour";
      case 2:
        return "day";
      case 3:
        return "week";
      case 4:
        return "month";
      default:
        return "";
    }
  };

  const getListingBookings = () => {
    setLoading(true);
    if (listing && listing?.owner_uid) {
      axios.get(`${process.env.REACT_APP_API_URL}/booking?listing_id=${listing?.listing_id}&page=${page}&page_size=${pageSize}&status=${selectedBooking === "cancelled" ? "4,9,10" : selectedBooking === "not-renewing" ? "8" : selectedBooking === "completed" ? "6,12" : selectedBooking === "past_due" ? "11" : ""}&send_upcoming=${selectedBooking === "upcoming" ? true : false}&send_ongoing=${selectedBooking === "progress" ? true : false}&sort=4`)
        .then((res) => {
          const responseUser = res.data;
          setBookings(responseUser.bookings?.filter((item) => item.listing_id === listing?.listing_id));
          setTotal(responseUser.total > 0 ? responseUser.total : 1);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  };

  const getBookingsCount = (id) => {
    axios.get(`${process.env.REACT_APP_API_URL}/booking/count-by-status?listing_id=${id}`)
      .then((res) => {
        let sum = 0;
        if (!isNaN(res.data?.[4])) {
          sum += res.data?.[4];
        }
        if (!isNaN(res.data?.[9])) {
          sum += res.data?.[9];
        }
        if (!isNaN(res.data?.[10])) {
          sum += res.data?.[10];
        }
        setBookingsCount({
          ...res.data,
          cancelled: sum
        });
      }).catch((err) => {
        console.log(err)
      });
  };

  useEffect(() => {
    getListingBookings();
  }, [listing?.owner_uid, page, pageSize, selectedBooking]);

  const updatePage = (type) => {
    if (type === "dec") {
      setPage(page - 1);
    } else if (type === "inc") {
      if (page !== Math.ceil(total / pageSize)) {
        setPage(page + 1);
      }
    }
  };

  const [allReviews, setAllReviews] = useState([])

  useEffect(() => {
    if (listing?.listing_id) {
      axios.get(`${process.env.REACT_APP_API_URL}/review?page=1&page_size=10&listing_id=${listing?.listing_id}`)
        .then(res => {
          setAllReviews(res?.data?.reviews)
        }).catch((err) => {
          console.log(err)
        })
    }
  }, [listing])

  const adminList = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/admin/public`).then((res) => {
      setAdmins(res.data);
    }).catch((err) => console.log(err));
  }

  const adminNotes = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/listing/${listing?.listing_id}/notes`).then((res) => {
      setNotes(res.data.reverse());
    }).catch((err) => console.log(err))
  }


  useEffect(() => {
    if (listing?.listing_id && !openNoteModal) {
      adminList()
      adminNotes()
    }
  }, [listing, openNoteModal])


  return listing ? (
    <>
      <KTCard>
        {(haveAccess(currentUser, 'listing_details')) ?
          <KTCardBody>
            <div style={{ width: "100%", height: "350px", position: "relative" }}>
              {listing.avatar ? (
                <img
                  src={
                    listing.avatar
                      ? process.env.REACT_APP_IMAGE_URL + "1920:720:" + listing?.avatar.slice(1)
                      : listing.images &&
                      listing.images?.length > 0 &&
                      process.env.REACT_APP_IMAGE_URL + "1920:720:" + listing?.images[0]?.file.slice(1)
                  }
                  alt=""
                  className="cover-img"
                  onClick={() =>
                    new ImageViewer({
                      images: listing?.images?.map((el) => ({
                        mainUrl: process.env.REACT_APP_IMAGE_URL + "1920:720:" + el.file.slice(1)
                      })),
                      isZoomable: false,
                      closeOnEsc: true
                    })
                  }
                />
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // border: "1px solid #ffa459",
                    background: "gray",
                    color: "#fff",
                    fontSize: "16px"
                  }}
                >
                  No Images Available Yet
                </div>
              )}
              <div
                className="symbol symbol-circle symbol-50px overflow-hidden me-3"
                style={{
                  width: "100px",
                  height: "100px",
                  position: "absolute",
                  bottom: "-40px",
                  left: "30px",
                  zIndex: "9"
                }}
              >
                <Link to={`/users/${listing.owner_uid}`}>
                  {listing.owner_avatar ? (
                    <div className="w-100 h-100">
                      <img
                        src={process.env.REACT_APP_IMAGE_URL + "300:200:" + listing?.owner_avatar?.slice(1)}
                        className="w-100 h-100"
                        alt=""
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "30px",
                        backgroundColor: "#ffa459",
                        color: "#fff",
                        fontWeight: "bold"
                      }}
                    >
                      {listing.owner_first_name[0]}
                      {listing.owner_last_name[0]}
                    </div>
                  )}
                </Link>
              </div>
              {listing?.images && listing?.images?.length > 1 && (
                <div className="overlay"
                  onClick={() =>
                    new ImageViewer({
                      images: listing?.images?.map((el) => ({
                        mainUrl: process.env.REACT_APP_IMAGE_URL + "1920:720:" + el.file.slice(1)
                      })),
                      isZoomable: false,
                      closeOnEsc: true
                    })
                  }
                >
                  {listing?.images && listing?.images?.length > 1 && listing?.images?.length + " Images"}
                </div>
              )}
            </div>

            <div className="row mt-20 g-4">
              <div className="col-lg-9">
                <div className="d-flex column-gap-7">
                  <div>
                    {(listing.vehicle_type_allowed == 2 || listing.vehicle_type_allowed == 1) && (
                      <h5 style={{ color: "#ed2626", marginBottom: "1.5rem" }}>
                        Only {listing.vehicle_type_allowed == 2 ? 'Trailers' : 'Trucks'} can be parked at this spot
                      </h5>
                    )
                    }
                    <h1 style={{ color: "#4a4a4a", fontSize: "25px" }}>
                      {listing.title}{" "}
                      <span
                        style={{
                          color:
                            listing.status === 5
                              ? "#ed2626"
                              : listing.status === 1
                                ? "#2ec771"
                                : listing.status === 0
                                  ? "#4a4a4a"
                                  : listing.status === 4
                                    ? "#ffa459"
                                    : "#009ef7",
                          fontSize: "18px"
                        }}
                      >
                        {
                          listing.status === -1
                            ? "(Deleted)"
                            : listing.status === 0
                              ? "(Draft)"
                              : listing.status === 1
                                ? "(Active)"
                                : listing.status === 2
                                  ? "(On Hold by User)"
                                  : listing.status === 3
                                    ? "(On Hold by Admin)"
                                    : listing.status === 4
                                      ? "(Pending)"
                                      : listing.status === 5
                                        ? "(Rejected)"
                                        : ""
                        }
                      </span>
                    </h1>
                    {(listing.state_slug && listing.city_slug && listing.details_slug) && (listing.status == 1 || listing.status == 4) && <h4><a
                      href={`${process.env.REACT_APP_API_CUSTOMER_URL}truck-parking/${listing.state_slug}/${listing.city_slug}/${listing.details_slug}${listing.is_qr_only ? "?parked=1" : ""}`}
                      target="_blank" rel="noreferrer"
                    >
                      (Go to listing page on main site)
                    </a>
                    </h4>
                    }
                    {isProd && <h4><a href={`${process.env.REACT_APP_HUBSPOT_DEAL_URL}${listing?.deal_id}`}
                      target="_blank" rel="noreferrer"
                    >(View hubspot property)</a>
                    </h4>
                    }
                    {+listing.status === 3 && listing.disable_reason && (
                      <h4 style={{ color: "#000", marginBottom: "10px", marginTop: "1.5rem", width: "90%" }}>
                        Disabled Reason: <span style={{ color: "#ed2626" }}>{listing.disable_reason}</span>
                      </h4>
                    )}
                    <h5 style={{ color: "#4a4a4a", marginTop: "1.5rem" }}>Created At: {convertUTCTimeToListingTime(listing.createdAt, listing.timezone_id, 0, "dddd, Do MMM YYYY, hh:mm A")}</h5>
                    {listing.approval_date && <h5 style={{ color: "#4a4a4a", marginTop: "1.5rem" }}>Approved At: {convertUTCTimeToListingTime(listing.approval_date, listing.timezone_id, 0, "dddd, Do MMMM YYYY, hh:mm A")}</h5>}
                    <h5 style={{ color: "#4a4a4a", marginTop: "1.5rem" }}>
                      Hosted By:
                      {" "}
                      <Link
                        to={(haveAccess(currentUser, 'user_details')) && `/users/${listing.owner_uid}`}
                        style={{
                          color: "#2ecc71",
                          fontWeight: "600"
                        }}
                      >
                        {listing.owner_first_name} {listing.owner_last_name}
                      </Link>
                    </h5>
                    <h5 style={{ color: "#4a4a4a", marginTop: "1.5rem" }}>
                      Total Spaces:
                      {" "}
                      {listing.seats ? listing.seats : "No Spaces added yet"}
                    </h5>
                    {listing?.seats && <h5 style={{ color: "#4a4a4a", marginTop: "1.5rem" }}>
                      Available Spaces:
                      {" "}
                      <span style={{ color: +listing?.available_seats <= 0 || listing?.is_zero ? "#ed2626" : "#ffa459" }}>
                        {+listing?.available_seats <= 0 || listing?.is_zero ? 0 : listing?.available_seats}
                      </span>
                    </h5>
                    }
                    {(listing?.open_time && listing?.close_time && (listing?.open_time !== listing?.close_time)) && (
                      <h5 style={{ color: "#4a4a4a", marginTop: "1.5rem" }}>
                        Reservations and check-ins must be between{' '}
                        <span style={{
                          fontWeight: '500',
                          fontStyle: 'italic',
                          textDecoration: 'underline'
                        }}>
                          {moment.utc(`${date} ${listing?.open_time}`).tz(listing?.timezone_id).format("LT")}
                        </span>
                        {' and '}
                        <span style={{
                          fontWeight: '500',
                          fontStyle: 'italic',
                          textDecoration: 'underline'
                        }}>
                          {moment.utc(`${date} ${listing?.close_time}`).tz(listing?.timezone_id).format("LT")}
                        </span>
                      </h5>
                    )}
                    <div className="gap-5px mt-5">
                      <h3 style={{ color: "#b2b2b2", fontSize: "20px" }}>Prices</h3>
                      <select
                        className="form-select form-select-solid fw-bolder"
                        data-kt-select2="true"
                        data-placeholder="Select option"
                        data-allow-clear="true"
                        data-kt-user-table-filter="two-step"
                        data-hide-search="true"
                        style={{ width: "max-content" }}
                        value={vehicleType}
                        onChange={(e) => setVehicleType(e.target.value)}
                        disabled={listing.vehicle_type_allowed && listing.vehicle_type_allowed !== 0}
                      >
                        <option value='1' disabled={listing.vehicle_type_allowed == 2}>Truck</option>
                        <option value='2' disabled={listing.vehicle_type_allowed == 1}>Trailer</option>
                        <option value='3' disabled={(listing.vehicle_type_allowed == 2 || listing.vehicle_type_allowed == 1)}>Truck + Trailer</option>
                      </select>
                    </div>
                    {getListingPrices(listing, vehicleType) && getListingPrices(listing, vehicleType).length > 0 ? (
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          alignItems: "center",
                        }}
                      >
                        {getListingPrices(listing, vehicleType)
                          .sort((a, b) => a.plan_duration - b.plan_duration).map(
                            (price) =>
                              price.price !== 0 && price.plan_duration !== 5 &&
                              (
                                <div
                                  style={{
                                    fontSize: "16px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center"
                                  }}
                                >
                                  <span
                                    className="mobile-price"
                                    style={{
                                      color: "#ffa459",
                                      fontWeight: "600"
                                    }}
                                  >
                                    ${(price.price / 100).toFixed(2)}
                                  </span>{" "}
                                  <span className="mobile-price-sub">
                                    per {duration(price?.plan_duration)}
                                  </span>
                                </div>
                              )
                          )}
                      </div>
                    ) : (
                      <div
                        style={{
                          color: "#ffa459",
                          fontWeight: "700",
                          fontSize: "18px",
                          marginTop: "1.5rem"
                        }}
                      >
                        No Prices Added Yet
                      </div>
                    )}

                    <div style={{ marginTop: "1.5rem" }}>
                      <h3 style={{ color: "#b2b2b2", fontSize: "20px" }}>
                        Address
                      </h3>
                      <h5
                        style={{
                          color: "#4a4a4a",
                          fontSize: "16px",
                          marginTop: "5px"
                        }}
                      >
                        {listing.full_address || "No Address Added Yet"}
                      </h5>
                    </div>
                    <div style={{ marginTop: "1.5rem" }}>
                      <h3 style={{ color: "#b2b2b2", fontSize: "20px" }}>
                        Identifier
                      </h3>
                      <h5
                        style={{
                          color: "#4a4a4a",
                          fontSize: "16px",
                          marginTop: "5px"
                        }}
                      >
                        {`${listing?.platform_fees}-${id}`}
                      </h5>
                    </div>
                    <div style={{ marginTop: "1.5rem" }}>
                      <h3 style={{ color: "#b2b2b2", fontSize: "20px" }}>
                        About this space
                      </h3>
                      <div
                        style={{
                          color: "#4a4a4a",
                          fontSize: "16px",
                          marginTop: "5px",
                          padding: 0
                        }}
                        className="ql-editor"
                        dangerouslySetInnerHTML={{ __html: listing.description || "No Description Added Yet" }}
                      />
                    </div>
                    <div style={{ marginTop: "1.5rem" }}>
                      <h3 style={{ color: "#b2b2b2", fontSize: "20px" }}>
                        Important information sent to the trucker
                      </h3>
                      <h5
                        style={{
                          color: "#4a4a4a",
                          fontSize: "16px",
                          marginTop: "5px"
                        }}
                      >
                        {listing.notes || "No Information Added Yet"}
                      </h5>
                    </div>
                    {currentUser?.access_level !== 3 && listing?.stripe_connect && (
                      <div style={{ marginTop: "1.5rem" }}>
                        <h3 style={{ color: "#b2b2b2", fontSize: "20px" }}>
                          Stripe Account ID
                        </h3>
                        <h5
                          style={{
                            fontSize: "20px",
                            marginTop: "5px",
                            color: "#2ecc71",
                            fontWeight: "900",
                            cursor: listing?.stripe_connect ? "pointer" : "default",
                            pointerEvents: listing?.stripe_connect ? "auto" : "none"
                          }}
                          onClick={() => {
                            if (isProd) {
                              window.open(
                                `https://dashboard.stripe.com/applications/users/${listing?.stripe_connect}`,
                                "_blank"
                              );
                            } else {
                              window.open(
                                `https://dashboard.stripe.com/test/applications/users/${listing?.stripe_connect}`,
                                "_blank"
                              );
                            }
                          }}
                        >
                          {listing?.stripe_connect}
                        </h5>
                      </div>
                    )}
                    {currentUser?.access_level !== 3 && (
                      <div style={{ marginTop: "1.5rem" }}>
                        <h3 style={{ color: "#b2b2b2", fontSize: "20px" }}>
                          Commission Rate
                        </h3>
                        <h5
                          style={{
                            fontSize: "20px",
                            marginTop: "5px",
                            color: "#2ecc71",
                            fontWeight: "900"
                          }}
                        >
                          {listing.platform_fees + "%" || "-"}
                        </h5>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-2 g-6">
                  <div className="d-flex gap-3 align-items-center">
                  <h3 style={{ color: "#b2b2b2", fontSize: "20px" }}>
                    Restricted Hours
                  </h3>
                  <button className="btn btn-sm p-2 text-white fs-7" style={{background:'#ffa459'}}
                  onClick={() => {
                    setSelectedListingId(listing.listing_id);
                    setOpenRestrictedHoursModal(true)
                  }}
                  >
                  <i className="fa fa-edit text-white"></i>Edit
                  </button>
                    </div>
                  <div className="row mt-4 g-2" style={{ maxWidth: '500px' }}>
                    {
                      listing?.restricted_hours && listing?.restricted_hours?.length > 0 ?
                        daysOfWeek?.map((day) => {
                          const restricted = listing?.restricted_hours.find(restrictedDay => restrictedDay.day_of_week === day.id);
                          if (restricted) {
                            return <div className="d-flex" key={day.id}>
                              <h5 style={{ flex: '1' }}>{day.name}</h5>
                              {!restricted.is_closed ?
                                <div style={{ flex: '1' }}>
                                  {restricted.times && restricted.times.length > 0 &&
                                    restricted.times.map((time, index) => {
                                      return <h5 key={index}>{`${moment(`${date} ${time.closes_at}`).format("LT")} - ${moment(`${date} ${time.opens_at}`).format("LT")}`}</h5>
                                    })
                                  }
                                </div>
                                :
                                <h5 style={{ flex: '1' }}>Closed</h5>
                              }
                            </div>
                          }
                          return null
                        })
                        :
                        <h4>No Restricted Hours Added</h4>
                    }
                    {
                      listing?.restricted_hours.filter((item) => item?.day_of_year) && listing?.restricted_hours.filter((item) => item?.day_of_year).length > 0 &&
                      <>
                        <h3>Special Hours:</h3>
                        {listing?.restricted_hours.filter((item) => item?.day_of_year).map((day) => {
                          return <div className="d-flex" key={day.id}>
                            <h5 style={{ flex: '1' }}>{convertUTCTimeToListingTime(day.day_of_year, listing.timezone_id, 0, "YYYY-MM-DD")} ({convertUTCTimeToListingTime(day.day_of_year, listing.timezone_id, 0, "dddd")})</h5>
                            <div className="d-flex gap-2" style={{ flex: '1' }}>
                              {!day.is_closed ?
                                <div>
                                  {day.times && day.times.length > 0 &&
                                    day.times.map((time, index) => {
                                      return <h5 key={index}>{`${moment(`${date} ${time.closes_at}`).format("LT")} - ${moment(`${date} ${time.opens_at}`).format("LT")}`}</h5>
                                    })
                                  }
                                </div>
                                :
                                <h5>Closed</h5>
                              }
                              <h5>({day.description})</h5>
                            </div>
                          </div>
                        })
                        }
                      </>
                    }
                  </div>
                </div>
                <div className="row mt-2 g-6">
                  {(haveAccess(currentUser, "listing_notes_list")) && notes && notes?.length > 0 &&
                    <div>
                      <h3 style={{ color: "#ed2626", fontSize: "20px" }}>
                        Notes
                      </h3>
                      <div className="row mt-4 g-2" style={{ width: "70%", maxHeight: '350px', overflowY: 'auto', minHeight: 'auto' }}>
                        {
                          notes && notes.length > 0 ?
                            <>
                              {notes.map((item, index) => {
                                return <div
                                  key={index}
                                  style={{ display: 'flex', gap: '5px', background: '#f9f9f9', marginBottom: '10px', padding: '10px', borderRadius: '10px', flexDirection: 'column' }}>
                                  <div className="d-flex justify-content-between">
                                    <label htmlFor="notes">Notes:</label>
                                    <button
                                      className="listing-remove"
                                      onClick={() => {
                                        setNoteId(item);
                                        setSelectedListingId(item.listing_id);
                                        setOpenNoteModal(true);
                                      }}
                                    >
                                      <KTIcon iconName="pencil" />
                                    </button>
                                  </div>
                                  <h6 style={{ marginBottom: '2px', textAlign: 'justify' }}> {item.notes}</h6>
                                  <div style={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
                                    <h6 style={{ marginBottom: '2px', fontWeight: 400 }}>Action Done By: <span style={{ color: '#ffa459', fontWeight: 700 }}>{item.action_by_role == 2 ? `${listing.owner_first_name} ${listing.owner_last_name} (Owner)` : item.action_by_role == 3 ? `${admins?.find((_i) => _i.id == item.action_by)?.name || ""} (Admin)` : '-'}</span></h6>
                                    <h6>{moment(item.createdAt).utc().format('YYYY-MM-DD')}</h6>
                                  </div>
                                  {/* {(item.action_id !== 10 && item.action_id !== 16 && item.action_id !== 17) && item.notes && <h6>{item.notes}</h6>} */}
                                </div>
                              })
                              }
                            </>
                            :
                            <h4>No Notes yet for this listing</h4>
                        }
                      </div>
                    </div>
                  }
                  {(haveAccess(currentUser, 'amenities_list')) && <div>
                    <h3 style={{ color: "#b2b2b2", fontSize: "20px" }}>
                      Amenities
                    </h3>
                    <div className="row mt-4 g-2" style={{ width: "70%" }}>
                      {!!amenities.length && listing && listing.amenities && amenities.map(el => (
                        <h4
                          key={el.id}
                          className={
                            listing.amenities.indexOf(el.id) > -1
                              ? "col-lg-6"
                              : "col-lg-6 checked"
                          }
                        >
                          {el.title}
                        </h4>
                      ))}
                    </div>
                  </div>
                  }
                  <div>
                    <h3 style={{ color: "#b2b2b2", fontSize: "20px" }}>
                      Location
                    </h3>
                    <div className="row mt-4 g-2 map">
                      <Map
                        defaultZoom={16}
                        defaultCenter={{
                          lat: Number(listing.lat),
                          lng: Number(listing.lng)
                        }}
                      />
                    </div>
                  </div>
                  {(haveAccess(currentUser, 'listing_user_create')) && < div className="mt-10">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                      }}
                    >
                      <h3
                        style={{
                          color: "#b2b2b2",
                          fontSize: "20px",
                          width: "70%"
                        }}
                      >
                        Additional User For Notification Sharing
                      </h3>
                      {(haveAccess(currentUser, 'listing_user_create')
                      ) && (
                          <button
                            type="button"
                            className="btn w-30"
                            onClick={() => {
                              setSelectedListingId(listing.listing_id);
                              setOpenAdditionalUserModal(true);
                            }}
                            style={{
                              background: "#4a4a4a",
                              color: "#fff",
                              height: "auto"
                            }}
                          >
                            Add Additional User
                          </button>
                        )}
                    </div>

                    <i style={{ color: "red" }}>Account Owner is notified and does not need to be added as an additional
                      user</i>

                    {listing.notification_users && listing.notification_users.length ? (
                      <div className="row g-2">
                        {listing.notification_users &&
                          listing?.notification_users?.map((user) => {
                            return (
                              <div className="col-12 col-md-6 col-lg-6 pt-3 g-6">
                                <div
                                  className="card card-custom g-10"
                                  style={{
                                    border: "1px solid #b2b2b2",
                                    padding: "0 10px",
                                    background: "rgba(244,244,244,0.4)",
                                    borderRadius: "10px"
                                  }}
                                >
                                  {(haveAccess(currentUser, 'listing_user_edit'))
                                    && (
                                      <>
                                        <span
                                          style={{
                                            position: "absolute",
                                            background: "#ed2626",
                                            width: "40px",
                                            height: "40px",
                                            borderRadius: "50%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            right: "-10px",
                                            top: "-10px",
                                            cursor: "pointer"
                                          }}
                                          onClick={() => {
                                            setSelectedListingId(listing.listing_id);
                                            setSelectedUser(user);
                                            setOpenAdditionalUserModal(true);
                                          }}
                                        >
                                          <i
                                            className="fas fa-pencil-alt"
                                            style={{ color: "#fff" }}
                                          />
                                        </span>
                                      </>
                                    )}

                                  <div
                                    className="row pt-4"
                                    style={{ height: "auto" }}
                                  >
                                    <h4
                                      style={{
                                        textAlign: "center",
                                        fontSize: "22px",
                                        marginBottom: "20px"
                                      }}
                                    >
                                      {user.name}
                                    </h4>
                                    <div className="fv-row mb-2">
                                      <h5 className="fw-bold fs-6 mb-2">Email</h5>
                                      <h3 style={{ color: "#ffa459" }}>
                                        {user.email ? user.email : "-"}
                                      </h3>
                                    </div>
                                    <div className="fv-row mb-2">
                                      <h5 className="fw-bold fs-6 mb-2">
                                        Phone Number
                                      </h5>
                                      <h3 style={{ color: "#ffa459" }}>
                                        {user?.phone ? formatPhone(user.phone) : "-"}
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      <div className="d-flex text-center w-100 align-content-center py-5">
                        <h4 className="text-primary">
                          No Additional User Added Yet..
                        </h4>
                      </div>
                    )}
                  </div>
                  }
                  {(haveAccess(currentUser, "booking_list")) && <div className="mt-10">
                    <h3 style={{ color: "#b2b2b2", fontSize: "20px" }}>Bookings</h3>
                    <div
                      className="booking-type-header"
                      style={{
                        display: "flex",
                        marginTop: "5px"
                      }}
                    >
                      <button
                        style={{
                          border: "none",
                          background: "transparent",
                          borderBottom:
                            selectedBooking === "progress"
                              ? "3px solid #ffa459"
                              : "none",
                          padding: "5px 2px",
                          fontWeight: 600,
                          color: "#ffa459"
                        }}
                        onClick={() => {
                          setSelectedBooking("progress");
                          setPage(1);
                        }}
                      >
                        Active (
                        {(bookingsCount['ongoing'] || 0)}
                        )
                      </button>
                      <button
                        style={{
                          border: "none",
                          background: "transparent",
                          borderBottom:
                            selectedBooking === "not-renewing"
                              ? "3px solid #5e6278"
                              : "none",
                          padding: "5px 2px",
                          fontWeight: 600,
                          color: "#5e6278"
                        }}
                        onClick={() => {
                          setSelectedBooking("not-renewing");
                          setPage(1);
                        }}
                      >
                        Active & Not Renewing (
                        {bookingsCount[8] ||
                          0}
                        )
                      </button>
                      <button
                        style={{
                          border: "none",
                          background: "transparent",
                          borderBottom:
                            selectedBooking === "upcoming"
                              ? "3px solid #009ef7"
                              : "none",
                          padding: "5px 2px",
                          fontWeight: 600,
                          color: "#009ef7"
                        }}
                        onClick={() => {
                          setSelectedBooking("upcoming");
                          setPage(1);
                        }}
                      >
                        Upcoming (
                        {bookingsCount?.upcoming ||
                          0}
                        )
                      </button>
                      <button
                        style={{
                          border: "none",
                          background: "transparent",
                          borderBottom:
                            selectedBooking === "completed"
                              ? "3px solid #2ecc71"
                              : "none",
                          padding: "5px 2px",
                          fontWeight: 600,
                          color: "#2ecc71"
                        }}
                        onClick={() => {
                          setSelectedBooking("completed");
                          setPage(1);
                        }}
                      >
                        Completed (
                        {(bookingsCount[6] ? bookingsCount[6] : 0) + (bookingsCount[12] ? bookingsCount[12] : 0)}
                        )
                      </button>
                      <button
                        style={{
                          border: "none",
                          background: "transparent",
                          borderBottom:
                            selectedBooking === "cancelled"
                              ? "3px solid #ed2626"
                              : "none",
                          padding: "5px 2px",
                          fontWeight: 600,
                          color: "#ed2626"
                        }}
                        onClick={() => {
                          setSelectedBooking("cancelled");
                          setPage(1);
                        }}
                      >
                        Cancelled (
                        {bookingsCount?.cancelled ||
                          0}
                        )
                      </button>
                      <button
                        style={{
                          border: "none",
                          background: "transparent",
                          borderBottom:
                            selectedBooking === "past_due"
                              ? "3px solid #ed2626"
                              : "none",
                          padding: "5px 2px",
                          fontWeight: 600,
                          color: "#ed2626"
                        }}
                        onClick={() => {
                          setSelectedBooking("past_due");
                          setPage(1);
                        }}
                      >
                        Past Due (
                        {bookingsCount[11] ||
                          0}
                        )
                      </button>
                    </div>
                    {loading && <UsersListLoading />}
                    {(bookings && !loading && bookings.length > 0) ? (
                      <div className="row g-5 mt-1 booking-wrapper">
                        {bookings && bookings?.map((item) => (
                          <div className="col-12 col-md-6 col-lg-6 pt-3">
                            <div
                              className="card card-custom g-10"
                              style={{
                                border: "1px solid #b2b2b2",
                                padding: "0 10px",
                                background: "rgba(244,244,244,0.4)",
                                borderRadius: "10px"
                              }}
                            >
                              <div
                                className="row pt-4"
                                style={{ height: "auto" }}
                              >
                                <div className="row">
                                  <div className="fv-row mb-2 col-6">
                                    <h5 className="fw-bold fs-6 mb-2">
                                      Reservation Code
                                    </h5>
                                    <Link
                                      to={(haveAccess(currentUser, "booking_details")) && `/bookings?search_type=reservation_code&search=${item.reservation_code}`}
                                    // state={{ reservationNumber: item.reservation_code }}
                                    >
                                      <h4
                                        style={{
                                          color: getColorByBookingStatus(selectedBooking),
                                          cursor: (haveAccess(currentUser, "booking_details")) ? "pointer" : "default"
                                        }}
                                      >
                                        {item.reservation_code?.toUpperCase()}
                                      </h4>
                                    </Link>
                                  </div>
                                  <div className="fv-row mb-2 col-6">
                                    <h5 className="fw-bold fs-6 mb-2">
                                      Clock In
                                    </h5>
                                    <h4
                                      style={{
                                        color: getColorByBookingStatus(selectedBooking)
                                      }}
                                    >
                                      {moment(
                                        item?.clock_in,
                                        "hh:mm"
                                      ).format("LT")}
                                    </h4>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="fv-row mb-3 col-6">
                                    <h5 className="fw-bold fs-6 mb-2">
                                      Start Date
                                    </h5>
                                    <h4
                                      style={{
                                        color: getColorByBookingStatus(selectedBooking)
                                      }}
                                    >
                                      {convertUTCTimeToListingTime(item.start_date, item.timezone_id, 0, "DD MMM, YYYY")}
                                    </h4>
                                  </div>
                                  <div className="fv-row mb-3 col-6">
                                    <h5 className="fw-bold fs-6 mb-2">
                                      End Date
                                    </h5>
                                    <h4
                                      style={{
                                        color: getColorByBookingStatus(selectedBooking)
                                      }}
                                    >
                                      {convertUTCTimeToListingTime(item.end_date, item.timezone_id, 1, "DD MMM, YYYY")}
                                    </h4>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="fv-row mb-3 col-6">
                                    <h5 className="fw-bold fs-6 mb-2"
                                    >
                                      Trucker Member
                                    </h5>
                                    <h4
                                      style={{
                                        color: getColorByBookingStatus(selectedBooking),
                                        cursor: (haveAccess(currentUser, "user_details")) ? "pointer" : "default"
                                      }}
                                      onClick={() => {
                                        (haveAccess(currentUser, "user_details")) && navigate(`/users/${item.customer_uid}`);
                                      }}
                                    >
                                      {item.customer_name || "-"}
                                    </h4>
                                  </div>
                                  <div className="fv-row mb-3 col-6">
                                    <h5 className="fw-bold fs-6 mb-2">
                                      Member Number
                                    </h5>
                                    <h4
                                      style={{
                                        color: getColorByBookingStatus(selectedBooking)
                                      }}
                                    >
                                      {item.customer_public_id || "-"}
                                    </h4>
                                  </div>
                                </div>
                                <div className="row">
                                  {+item.booking_type !== 1 && (
                                    <div className="fv-row mb-3 col-6">
                                      <h5 className="fw-bold fs-6 mb-2">
                                        Seats
                                      </h5>
                                      <h4
                                        style={{
                                          color: getColorByBookingStatus(selectedBooking)
                                        }}
                                      >
                                        {item.seats || "-"}
                                      </h4>
                                    </div>
                                  )}
                                  {+item.booking_type === 1 && (
                                    <div className="fv-row mb-3 col-6">
                                      <h5 className="fw-bold fs-6 mb-2">
                                        Hours
                                      </h5>
                                      <h4
                                        style={{
                                          color: getColorByBookingStatus(selectedBooking)
                                        }}
                                      >
                                        {moment(item.end_date).add(1, "minutes").diff(moment(item.start_date), "hours") || "-"}
                                      </h4>
                                    </div>
                                  )}
                                  {selectedBooking === "completed" && <div className="fv-row mb-3 col-6">
                                    <h5 className="fw-bold fs-6 mb-2">
                                      Status
                                    </h5>
                                    <h4
                                      style={{
                                        color: getColorByBookingStatus(item.status),
                                      }}
                                    >
                                      {item.status == 12 ? 'Suspended' : 'Completed'}
                                    </h4>
                                  </div>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : !loading && (
                      <div className="row mt-10">
                        <h5>
                          {" "}
                          No{" "}
                          {selectedBooking === "upcoming"
                            ? "Upcomings"
                            : selectedBooking === "progress"
                              ? "Active"
                              : selectedBooking === "cancelled"
                                ? "Cancelled"
                                : selectedBooking === "not-renewing" ?
                                  "Not Renewing"
                                  : selectedBooking === "past_due" ? "Past Due" :
                                    "Completed"}{" "}
                          Bookings Yet
                        </h5>
                      </div>
                    )}
                    <div className="row mt-5">
                      <div
                        className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                          <select
                            className="form-select form-select-solid fw-bolder"
                            data-kt-select2="true"
                            data-placeholder="Select Status"
                            data-allow-clear="true"
                            data-kt-user-table-filter="two-step"
                            data-hide-search="true"
                            onChange={(e) => setPageSize(e.target.value)}
                            value={pageSize}
                          >
                            <option value="30">30</option>
                            <option value="60">60</option>
                            <option value="90">90</option>
                            <option value="120">120</option>
                          </select>
                        </div>
                      </div>
                      <div
                        className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-lg-end">
                        <div id="kt_table_users_paginate">
                          <ul className="pagination">
                            {page > 1 && (
                              <li className={clsx("page-item", "previous")}>
                                <button
                                  className={clsx("page-link", "next", "page-text")}
                                  onClick={() => updatePage("dec")}
                                  style={{ cursor: "pointer" }}
                                  disabled={page === 1}
                                >
                                  Previous Page
                                </button>
                              </li>
                            )}
                            <div style={{ display: "flex", gap: "5px", fontWeight: 700, fontSize: "14px" }}>
                              <span style={{ color: "#ffa459" }}>{page}</span>/ {Math.ceil(total / pageSize)}
                            </div>
                            <li className={clsx("page-item", "previous")}>
                              <button
                                className={clsx("page-link")}
                                onClick={() => updatePage("inc")}
                                style={{
                                  cursor: page !== Math.ceil(total / pageSize) ? "pointer" : "default",
                                  background: page !== Math.ceil(total / pageSize) ? "transparent" : "#e6e6e6",
                                  color: page !== Math.ceil(total / pageSize) ? "#5e6278" : "#b2b2b2",
                                  marginLeft: page === Math.ceil(total / pageSize) && "10px"
                                }}
                              >
                                Next Page
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  }
                  <div className="separator separator-dashed my-7" />
                  {(haveAccess(currentUser, "reviews")) && <Reviews allReviews={allReviews} />}
                  <div>
                    <h3 style={{ color: "#b2b2b2", fontSize: "20px" }}>Host</h3>
                    <div
                      style={{
                        display: "flex",
                        gap: "15px",
                        alignItems: "center"
                      }}
                    >
                      <div
                        className="symbol symbol-circle symbol-50px overflow-hidden me-3"
                        style={{
                          width: "60px",
                          height: "60px"
                        }}
                      >
                        <Link
                          to={(haveAccess(currentUser, "user_details")) && `/users/${listing.owner_uid}`}>
                          {listing.owner_avatar ? (
                            <div className="symbol-label w-100 h-100">
                              <img
                                src={
                                  process.env.REACT_APP_IMAGE_URL + "200:200:" +
                                  listing?.owner_avatar?.slice(1)
                                }
                                className="w-100"
                                alt=""
                              />
                            </div>
                          ) : (
                            <div
                              className={clsx("fs-3")}
                              style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "25px !important",
                                backgroundColor: "#ffa459",
                                color: "#fff",
                                fontWeight: "600"
                              }}
                            >
                              {listing.owner_first_name[0]}
                              {listing.owner_last_name[0]}
                            </div>
                          )}
                        </Link>
                      </div>
                      <div>
                        <h5
                          style={{
                            color: "#4a4a4a",
                            fontSize: "18px",
                            marginTop: "5px"
                          }}
                        >
                          <Link
                            to={(haveAccess(currentUser, "user_details")) && `/users/${listing.owner_uid}`}
                            style={{
                              color: "#4a4a4a",
                              fontWeight: "600"
                            }}
                          >
                            {listing.owner_first_name} {listing.owner_last_name}
                          </Link>
                        </h5>
                        <h6>
                          {" "}
                          <a
                            href={`tel:${listing?.phone} `}
                            style={{ color: "#ffa459" }}
                          >
                            {formatPhone(listing?.phone)}
                          </a>
                        </h6>
                        {(haveAccess(currentUser, "user_details")) && (
                          <Link
                            to={`/users/${listing.owner_uid}`}
                            style={{
                              color: "#ffa459",
                              fontSize: "14px",
                              fontWeight: "600"
                            }}
                          >
                            See Profile
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                  <div style={{ fontSize: "16px", lineHeight: "32px" }}>
                    For questions, call{" "}
                    <a href="tel:+18888997275" style={{ color: "#ffa459" }}>
                      {formatPhone("18888997275")}
                    </a>
                  </div>
                </div>
              </div>
              {/* {(+currentUser.role === 1 || +currentUser.role === 2
                || (+currentUser.role === 4 && currentUser?.access?.find((item) => item.listing_id === listing.listing_id))
                || (+currentUser.role === 5 && currentUser?.access?.find((item) => item.listing_id === listing.listing_id))
                || (+currentUser.role === 6 && currentUser?.access?.find((item) => item.listing_id === listing.listing_id))
              ) && ( */}
              <div
                className="col-lg-3"
                style={{
                  display: "flex",
                  gap: "5px",
                  flexDirection: "column"
                }}
              >
                {listing.status !== -1 && (haveAccess(currentUser, "promo_list")) && <button
                  type="button"
                  className="btn w-100"
                  onClick={() => {
                    setSelectedListingId(listing.listing_id);
                    setOpenPromoModal(true);
                  }}
                  style={{
                    background: "#2fedce",
                    color: "#1e1e1e",
                    minHeight: "46px",
                    height: "auto",
                  }}
                >
                  Available Promo Codes
                </button>
                }
                {(listing.status !== -1 && currentUser?.access_level !== 3 && (haveAccess(currentUser, "listing_edit"))) && (
                  <button
                    type="button"
                    className="btn w-100 disable-btn"
                    style={{
                      background: "#ffc700",
                      color: "#000",
                      minHeight: "46px",
                      height: "auto",
                    }}
                    onClick={() => {
                      setSelectedListingId(listing.listing_id);
                      setOpenStripeConfirmModal(true);
                    }}
                    disabled={listing?.allow_stripe}
                  >
                    {listing?.allow_stripe && <i className="fas fa-check" style={{ color: '#000' }} />} {!listing?.allow_stripe ? 'Enable Stripe Connect' : 'Stripe Connect Enabled'}
                  </button>
                )}
                {haveAccess(currentUser, "listing_tracker_logs_list") && (
                  <button
                    type="button"
                    className="btn w-100 bg-secondary"
                    style={{
                      color: "#000",
                      minHeight: "46px",
                      height: "auto",
                    }}
                    onClick={() => {
                      setSelectedListingId(listing.listing_id);
                      setOpenTimelineModal(true);
                    }}
                  >
                    View Logs
                  </button>
                )}
                {(+listing.status === 4 && (haveAccess(currentUser, "listing_request_response"))) && (
                  <>
                    <button
                      type="button"
                      className="btn w-100 bg-success"
                      style={{
                        color: "#fff",
                        minHeight: "46px",
                        height: "auto",
                      }}
                      onClick={() => {
                        setSelectedListingId(listing.listing_id);
                        setOpenPendingUserModal(true);
                      }}
                    >
                      Pending For Approval
                    </button>
                  </>
                )}
                {(listing.status !== -1 && currentUser?.access_level !== 3 && (haveAccess(currentUser, "listing_edit"))) && (
                  <button
                    type="button"
                    className="btn w-100"
                    style={{
                      background: "#ffa459",
                      color: "#fff",
                      minHeight: "46px",
                      height: "auto",
                    }}
                    onClick={() => {
                      setSelectedListingId(listing.listing_id);
                      setOpenEditListing(true);
                    }}
                  >
                    Edit Listing
                  </button>
                )}
                {(listing.status !== -1 && currentUser?.access_level !== 3 && (haveAccess(currentUser, "listing_edit"))) && (
                  <button
                    type="button"
                    className="btn w-100"
                    style={{
                      background: "#2ecc71",
                      color: "#fff",
                      minHeight: "46px",
                      height: "auto",
                    }}
                    onClick={() => {
                      setSelectedListingId(listing.listing_id);
                      setOpenRestrictedHoursModal(true);
                    }}
                  >
                    Restricted Hours
                  </button>
                )}
                {(listing.status !== -1 && (haveAccess(currentUser, "listing_seats_edit") && currentUser?.access?.find((item) => item.listing_id === listing.listing_id))) && (
                  <button
                    type="button"
                    className="btn w-100"
                    style={{
                      background: "#ffa459",
                      color: "#fff",
                      minHeight: "46px",
                      height: "auto",
                    }}
                    onClick={() => {
                      setSelectedListingId(listing.listing_id);
                      setOpenEditListing(true);
                    }}
                  >
                    Edit Seats
                  </button>
                )}
                {/* {(listing.status !== -1 && (haveAccess(currentUser,  "listing_user_create") && currentUser?.access?.find((item) => item.listing_id === listing.listing_id))) && (
                <button
                  type="button"
                  className="btn w-100 bg-success"
                  onClick={() => {
                    setSelectedListingId(listing.listing_id);
                    setOpenAddModal(true);
                  }}
                  style={{
                    color: "#fff",
                    minHeight: "46px",
                    height: "auto",
                  }}
                >
                  Add Property Editor/Guard
                </button>
              )} */}
                {((+listing.status === 1 || +listing.status === 2 || +listing.status === 3)
                  && (haveAccess(currentUser, "listing_update_status"))) &&
                  (
                    <button
                      type="button"
                      className="btn w-100 bg-dark"
                      onClick={() => {
                        setSelectedListingId(listing.listing_id);
                        setOpenDisableModal(true);
                      }}
                      style={{
                        color: "#fff",
                        minHeight: "46px",
                        height: "auto",
                      }}
                    >
                      {listing.status === 3 || listing.status === 2
                        ? "Enable Listing"
                        : "Disable Listing"}
                    </button>
                  )}
                {(listing.status !== -1 && (haveAccess(currentUser, "listing_update_fees"))) && (
                  <button
                    type="button"
                    className="btn w-100 bg-primary"
                    onClick={() => {
                      setSelectedListingId(listing.listing_id);
                      setOpenCommissionModal(true);
                    }}
                    style={{
                      color: "#fff",
                      minHeight: "46px",
                      height: "auto",
                    }}
                  >
                    Change Commission Rate
                  </button>
                )}
                {(listing.status !== -1 && (haveAccess(currentUser, "listing_notes_add"))) && (
                  <button
                    type="button"
                    className="btn w-100"
                    onClick={() => {
                      setSelectedListingId(listing.listing_id);
                      setOpenNoteModal(true);
                    }}
                    style={{
                      background: "#ffc700",
                      color: "#000",
                      minHeight: "46px",
                      height: "auto",
                    }}
                  >
                    Admin Note
                  </button>
                )}
                {listing.status !== -1 && (haveAccess(currentUser, "listing_delete")) && (
                  <button
                    type="button"
                    className="btn w-100"
                    onClick={() => {
                      setSelectedListingId(listing.listing_id);
                      setOpenDeleteModal(true);
                    }}
                    style={{
                      background: "#ed2626",
                      color: "#fff",
                      minHeight: "46px",
                      height: "auto",
                    }}
                  >
                    Delete Listing
                  </button>
                )}
                {listing.is_qr_only && (
                  <div className="mt-3 fs-6 form-check form-check-custom form-check-solid" style={{
                    background: "#e3feee9e",
                    display: "flex",
                    flexDirection: 'column',
                    alignItems: "center",
                    padding: "10px 16px",
                    borderRadius: "10px",
                    flexWrap: "wrap"
                  }}>
                    <div className="fv-row w-100">
                      <label className="fw-bold fs-6 mb-2">Text2Pay Code:</label>
                      <h5 style={{ color: "#ffa459", fontWeight: 700 }}>{listing?.textcode}</h5>
                    </div>
                    <div className="fv-row w-100">
                      <label className="fw-bold fs-6 mb-2">Text2Pay Phone Number:</label>
                      <h5 style={{ color: "#ffa459", fontWeight: 700 }}>{process.env.REACT_APP_TEXT2PAY_NUMBER}</h5>
                    </div>
                  </div>
                )}
              </div>
              {/* )} */}
            </div>
          </KTCardBody>
          :
          <div className='d-flex text-center w-100 align-content-center justify-content-center' style={{ height: '70vh', alignItems: 'center', fontSize: '42px', color: '#ffa049', background: 'rgba(0,0,0,0.1)' }}>
            You do not have permission to view
          </div>
        }

        {(selectedListingId && (openEditListing || openCommissionModal)) && (
          <UserEditModal
            selectedListingId={selectedListingId}
            setSelectedListingId={setSelectedListingId}
            openEditListing={openEditListing}
            setOpenEditListing={setOpenEditListing}
            openCommissionModal={openCommissionModal}
            setOpenCommissionModal={setOpenCommissionModal}
            listing={listing}
            getListing={getListing}
          />
        )}
        {(selectedListingId && openAdditionalUserModal) && (
          <AddAdditionalUserModal
            selectedListingId={selectedListingId}
            setSelectedListingId={setSelectedListingId}
            listing={listing}
            getListing={getListing}
            openAdditionalUserModal={openAdditionalUserModal}
            setOpenAdditionalUserModal={setOpenAdditionalUserModal}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
          />
        )}
        {(selectedListingId && (openPendingUserModal || openDisableModal || openNoteModal)) && (
          <PendingListingModal
            selectedListingId={selectedListingId}
            setSelectedListingId={setSelectedListingId}
            listing={listing}
            getListing={getListing}
            openAdditionalUserModal={openPendingUserModal}
            setOpenAdditionalUserModal={setOpenPendingUserModal}
            openDisableModal={openDisableModal}
            setOpenDisableModal={setOpenDisableModal}
            openNoteModal={openNoteModal}
            setOpenNoteModal={setOpenNoteModal}
            admins={admins}
            notes={notes}
            adminNotes={adminNotes}
            noteId={noteId}
            setNoteId={setNoteId}
          />
        )}
        {(selectedListingId && openDeleteModal) && (
          <DeleteUserModal
            selectedListingId={selectedListingId}
            setSelectedListingId={setSelectedListingId}
            listing={listing}
            getListing={getListing}
            openDeleteModal={openDeleteModal}
            setOpenDeleteModal={setOpenDeleteModal}
          />
        )}
        {(selectedListingId && openStripeConfirmModal) && (
          <StripeConfirmModal
            selectedListingId={selectedListingId}
            setSelectedListingId={setSelectedListingId}
            listing={listing}
            getListing={getListing}
            openStripeConfirmModal={openStripeConfirmModal}
            setOpenStripeConfirmModal={setOpenStripeConfirmModal}
          />
        )}
        {(selectedListingId && openTimelineModal) && (
          <TimeLineModal
            selectedListingId={selectedListingId}
            setSelectedListingId={setSelectedListingId}
            listing={listing}
            getListing={getListing}
            openTimelineModal={openTimelineModal}
            setOpenTimelineModal={setOpenTimelineModal}
          />
        )}
        {(selectedListingId && openPromoModal) && (
          <PromoModal
            selectedListingId={selectedListingId}
            setSelectedListingId={setSelectedListingId}
            listing={listing}
            getListing={getListing}
            openPromoModal={openPromoModal}
            setOpenPromoModal={setOpenPromoModal}
          />
        )}
        {(selectedListingId && openAddModal) && (
          <AdminUserModal
            selectedListingId={selectedListingId}
            setSelectedListingId={setSelectedListingId}
            listing={listing}
            getListing={getListing}
            openAddModal={openAddModal}
            setOpenAddModal={setOpenAddModal}
          />
        )}
        {(selectedListingId && openRestrictedHoursModal) && (
          <RestrictedHoursModal
            selectedListingId={selectedListingId}
            setSelectedListingId={setSelectedListingId}
            listing={listing}
            getListing={getListing}
            setOpenRestrictedHoursModal={setOpenRestrictedHoursModal}
          />
        )}
      </KTCard >
    </>
  ) : (
    <div className="d-flex text-center w-100 align-content-center justify-content-center">
      No matching records found
    </div>
  );
};

export default SingleListing;
