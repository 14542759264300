import { RestrictedHoursModalForm } from "./RestrictedHoursModalForm";

const RestrictedHoursModalFormWrapper = ({
  selectedListingId,
  setSelectedListingId,
  listing,
  getListing,
  setOpenRestrictedHoursModal,
}) => {
  return (
    <RestrictedHoursModalForm
    selectedListingId={selectedListingId}
    setSelectedListingId={setSelectedListingId}
    listing={listing}
    getListing={getListing}
    setOpenRestrictedHoursModal={setOpenRestrictedHoursModal}
    />
  );
};

export { RestrictedHoursModalFormWrapper };
