import { MembersModalForm } from "./MembersModalForm";

const MembersModalFormWrapper = ({
  members,
  onClose
}) => {
  return (
    <MembersModalForm
      members={members}
      onClose={onClose}
    />
  );
};

export { MembersModalFormWrapper };
