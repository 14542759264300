import {KTIcon} from '../../../../../_metronic/helpers'
const RestrictedHoursModalHeader = ({
  setSelectedListingId,
  listing,
  setOpenRestrictedHoursModal,
}) => {  
  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>Restricted Hours for {listing.title}</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => {
          setSelectedListingId(null)
          setOpenRestrictedHoursModal(false)
        }}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {RestrictedHoursModalHeader}
