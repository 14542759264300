import { useNavigate } from "react-router-dom";

const MembersModalForm = ({
  members,
  onClose
}) => {
const navigate = useNavigate()

const orgRole = (roleId) => {
  switch (roleId) {
    case 1:
      return "Owner";
    case 2:
      return "Manager";
    case 3:
      return "Trucker";
    case 4:
      return "Property Guard"
  }
}



  return (
    <>
      <form className="form" id="kt_modal_add_user_form" autoComplete="off">
        <div
          className="d-flex flex-column scroll-y me-n7"
        >
          <div className="members-table">
            <h5>Member Name</h5>
            <h5>Member ID</h5>
            <h5>Organization Role</h5>
          </div>
          {members.map(el => (
            <div key={el?.member_id}  className="members-table">
              <h4 className="pointer hover"
              onClick={() => navigate(`/users/${el?.member_uid}`)}
              >{el?.member_name}</h4>
              <h4 className="pointer hover"
               onClick={() => navigate(`/users/${el?.member_uid}`)}
              >{el?.public_id}</h4>
              <h4
              >{orgRole(el?.role)}</h4>
            </div>
          ))}
        </div>
        <div className="text-center pt-15">
          <button
            type="reset"
            onClick={onClose}
            className="btn btn-light mx-3"
            data-kt-users-modal-action="cancel"
          >
            Close
          </button>
        </div>
      </form>
    </>
  );
};

export { MembersModalForm };
