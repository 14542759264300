import React, { useState, useEffect, useRef } from 'react'
import { KTIcon, useDebounce } from "../../../../_metronic/helpers";
import { UsersListFilter } from './UsersListFilter'
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth';
import {
  RegionDropdown,
} from "react-country-region-selector";

const Header = ({ search, setSearch, filter, setFilter, amenities, setAmenities, searchType, setSearchType, isZero, setIsZero }) => {
  const { currentUser, haveAccess } = useAuth()
  const navigate = useNavigate()
  const amenitiesRef = useRef();
  const notificationRef = useRef();
  const [openAmenities, setOpenAmenities] = useState(false)
  const [openNotification, setOpenNotification] = useState(false)
  const [listings, setListings] = useState([])

  const useOutsideClick = (ref, callback) => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };

    useEffect(() => {
      document.addEventListener('click', handleClick);

      return () => {
        document.removeEventListener('click', handleClick);
      };
    });
  };

  useOutsideClick(amenitiesRef, () => {
    if (openAmenities) setOpenAmenities(false);
  });

  const [amenitiesData, setAmenitiesData] = useState([])
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/listing/amenities`).then((res) => {
      setAmenitiesData(res.data);
    }).catch((err) => {
      toast(err, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }, [])

  useOutsideClick(notificationRef, () => {
    if (openNotification) setOpenNotification(false);
  });


  const getLatestListings = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/listing/latest?page=1&page_size=7&sort=4`).then((res) => {
      setListings(res.data.data);
    })
  }

  useEffect(() => {
    if (currentUser?.access_level == 1 || currentUser?.access_level == 2) {
      getLatestListings();
    }
  }, [])

  const checkIsSelected = (id) => amenities.includes(id);

  const addAmenities = (item) => {
    if (!checkIsSelected(item)) {
      setAmenities([...amenities, item]);
    } else {
      setAmenities(amenities.filter((i) => i !== item));
    }
  };

  return (
    <>
      <div className="card-header border-0 pt-6">
        <div className="d-flex align-items-center position-relative my-1 search-wrapper" style={{ gap: "15px" }}>
          <select
            className="form-select form-select-solid fw-bolder"
            data-kt-select2="true"
            data-placeholder="Select option"
            data-allow-clear="true"
            data-kt-user-table-filter="two-step"
            data-hide-search="true"
            value={searchType}
            onChange={(e) => {
              setSearch("")
              setSearchType(e.target.value)
            }}
          >
            <option value="search" selected>
              Listing Name
            </option>
            <option value="owner_name">
              Property Member Name
            </option>
            <option value="zip_code">
              Zip Code
            </option>
            <option value="city">
              City
            </option>
            <option value="state">
              State
            </option>
          </select>

          {searchType &&
            <div className="d-flex align-items-center position-relative my-1">
              <KTIcon
                iconName="magnifier"
                className="fs-1 position-absolute ms-6"
              />
              {(searchType === "search" || searchType === "owner_name" || searchType === "city" || searchType === "zip_code") ? <input
                type="text"
                data-kt-user-table-filter="search"
                className="form-control form-control-solid w-300px ps-14"
                placeholder={`Search by ${searchType === "search" ? "Listing Name" :
                  searchType === "owner_name" ? "Property Member Name" :
                    searchType === "city" ? "City" :
                      searchType === "zip_code" ? "Zip Code" : ""
                  }`}
                value={search}
                onChange={(e) => searchType === "zip_code" ? setSearch(e.target.value.replace(/[^0-9]/g, '')) : setSearch(e.target.value)}
              />
                :
                <RegionDropdown className='form-select form-select-solid fw-bolder w-300px ps-14'
                  country={'United States'}
                  value={search}
                  blacklist={{
                    US: ["AA", "AE", "AP"]
                  }}
                  defaultOptionLabel="Select State"
                  onChange={(val) => setSearch(val)} />
              }
            </div>
          }
        </div>
        {/* begin::Card toolbar */}
        <div className="card-toolbar">
          <div
            className="d-flex justify-content-end"
            data-kt-user-table-toolbar="base"
          >
            {haveAccess(currentUser, 'listing_list') && currentUser?.access_level !== 3 && <div
              style={{
                position: 'relative',
                cursor: 'pointer',
                width: 'max-content',
                height: '43px',
                marginRight: '15px',
                border: 'solid 1px rgba(255, 255, 255, 0.08)',
                borderRadius: '5px',
                background: '#f9f9f9',
                display: 'flex',
                alignItems: 'center',
                padding: '0 15px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
              }}
              className='fw-bolder'
              ref={notificationRef}
              onClick={() => {
                setOpenNotification(!openNotification)
              }}
            >
              <span style={{ overflow: 'auto', width: '100%', color: '#5e6278' }}
              >
                <KTIcon iconName="notification-on" className="fs-2 text-danger" />
              </span>
              {openNotification && (
                <div
                  style={{
                    position: 'absolute',
                    width: '300px',
                    minHeight: 'max-content',
                    overflowY: 'auto',
                    maxHeight: '300px',
                    left: '0%',
                    top: '115%',
                    borderRadius: '5px',
                    border: 'solid 1px rgba(255, 255, 255, 0.08)',
                    background: '#f9f9f9',
                    zIndex: 99,
                    minWidth: '10rem',
                    fontSize: '1.1rem',
                    color: '#000',
                    textAlign: 'left',
                    padding: '10px 15px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
                  }}
                >
                  {listings && listings.length > 0 ? listings.map((item, index) => (
                    <div
                      style={{
                        display: 'flex',
                        gap: '15px',
                        marginLeft: 0,
                        alignItems: 'center',
                        color: '#5e6278',
                        fontSize: '16px',
                        marginBottom: '10px'
                      }}
                      onClick={() => {
                        navigate(`/listings/${item.listing_id}`)
                      }}
                      key={index}
                    >
                      <span style={{ cursor: 'pointer' }}> {item.title}</span>
                    </div>

                  ))
                    :
                    <div
                      style={{
                        display: 'flex',
                        gap: '15px',
                        marginLeft: 0,
                        alignItems: 'center',
                        color: '#ed2626',
                        fontSize: '16px',
                      }}
                    >
                      <span>No New Listings Added</span>
                    </div>
                  }
                </div>
              )}
            </div>
            }
            {haveAccess(currentUser, "amenities_list") && <div
              style={{
                position: 'relative',
                cursor: 'pointer',
                width: '180px',
                height: '43px',
                marginRight: '15px',
                border: 'solid 1px rgba(255, 255, 255, 0.08)',
                borderRadius: '5px',
                background: '#f9f9f9',
                display: 'flex',
                alignItems: 'center',
                padding: '0 15px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
              }}
              className='fw-bolder'
              ref={amenitiesRef}
            >
              <span style={{ overflow: 'auto', width: '100%', color: '#5e6278' }}
                onClick={() => {
                  setOpenAmenities(!openAmenities)
                }}>
                {amenities.length ? 'Amenities Selected' : 'Search by Amenities'}
              </span>
              {openAmenities && (
                <div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    left: '0%',
                    top: '115%',
                    borderRadius: '5px',
                    border: 'solid 1px rgba(255, 255, 255, 0.08)',
                    background: '#e6e6e6',
                    height: 'max-content',
                    zIndex: 99,
                    minWidth: '10rem',
                    fontSize: '1.1rem',
                    color: '#000',
                    textAlign: 'left',
                    padding: '10px 15px',
                  }}
                >
                  {amenitiesData.map(el => (
                    <div
                      style={{
                        display: 'flex',
                        gap: '15px',
                        marginLeft: 0,
                        alignItems: 'center',
                        cursor: 'pointer',
                        marginBottom: '4px',
                        color:
                          amenities.includes(el.id)
                            ? '#ffa459'
                            : '#5e6278'
                      }}

                      onClick={() => addAmenities(el.id)}
                      key={el.id}
                    >
                      <span>{el.title}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
            }
            <UsersListFilter setFilter={setFilter} filter={filter} />
            {/* begin::Add user */}
            {/* <button
          type="button"
          className="btn btn-primary"
          // onClick={openAddUserModal}
        >
          <KTIcon iconName="plus" className="fs-2" />
          Add Booking
        </button> */}
            {/* end::Add user */}
          </div>
          {/* begin::Group actions */}
          {/* {selected.length > 0 ? <UsersListGrouping /> : <UsersListToolbar />} */}
          {/* end::Group actions */}
        </div>
        {/* end::Card toolbar */}
      </div>
      <div className=" card-header form-check form-check-custom form-check-solid mb-2 border-0 pt-1 justify-content-start" style={{minHeight:'auto'}}>
        <input
          className="form-check-input"
          type="checkbox"
          id='isZero'
          value={filter?.isZero}
          checked={filter?.isZero}
          onChange={() => setFilter({
            ...filter,
            isZero: !filter?.isZero
          })}
        />
        <label
          className="form-check-label"
          htmlFor="isZero"
          style={{ fontWeight: "700", color: "#3f4254" }}
        >
          Show 0 Space Listings Only
        </label>
      </div>
    </>
  )
}

export default Header
